import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import { authenticationService } from './auth/authenticationService';
import { configurationService } from './configsServices/appConfigService';
import { authenticationConfigService } from './configsServices/authenticationConfigService';
import { feedbackService } from './configsServices/feedbackService';
import './configsServices/localizationService';
import './index.css';
import reportWebVitals from './reportWebVitals';

configurationService.load().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    authenticationConfigService.initialize();
    authenticationService.subscribeLogoutEvents();
    feedbackService.initialize();

    const isProd = process.env.REACT_APP_ENV === 'production';
    const AppContainer: React.FC = () => (
        <RecoilRoot>
            <MsalProvider instance={authenticationConfigService.msalInstance}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </MsalProvider>
        </RecoilRoot>
    );

    root.render(
        //TODO: Temporary ternary, need to be reworked after Azure pipeline correction
        isProd ? (
            <AppContainer />
        ) : (
            <React.StrictMode>
                <AppContainer />
            </React.StrictMode>
        ),
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
