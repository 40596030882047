import MenuIcon from '@mui/icons-material/Menu';
import { SxProps, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme/theme';
import { UserRoles } from '../types/UserTypes';
import { SystemMenu } from '../views/SystemMenu/SystemMenu';
import { CreditorSelectorControl } from '../views/common/CreditorSelectorControl';
import { AccountInfo } from './AccountInfo';
import { Logo } from './Icons/Logo';
import { useCurrentUserService } from './hooks/useCurrentUserService';
import { LocalizationSwitcher } from './switchers/LocalizationSwitcher';

export function NavigationBar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { t } = useTranslation();
    const { currentUser } = useCurrentUserService();

    const pages = [
        'statistics',
        'invoices',
        'collection-history',
        'customers',
        ...(currentUser?.role !== UserRoles.CreditorUser ? ['admin'] : []),
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar
            position='static'
            color='transparent'
            elevation={0}
            sx={{ px: 2, borderBottom: `1px solid ${theme.palette.neutral5}`, mb: 3 }}
        >
            <Toolbar disableGutters>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: '55px' }}>
                    <Link to={pages[0]}>
                        <Logo />
                    </Link>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size='large'
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={handleOpenNavMenu}
                        color='inherit'
                    >
                        <MenuIcon
                            style={{
                                fontSize: '32px',
                                color: theme.palette.secondary.contrastText,
                            }}
                        />
                    </IconButton>
                    <Menu
                        id='menu-appbar'
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                <Typography
                                    textAlign='center'
                                    color={theme.palette.secondary.contrastText}
                                >
                                    <Link
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                        to={`/${page}`}
                                    >
                                        {t(`pages.${page}`)}
                                    </Link>
                                </Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Box
                        sx={{
                            ml: 'calc(50% - 24px)',
                            transform: 'translate(-50%,5px)',
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <Link to={pages[0]}>
                            <Box sx={{ scale: '0.75' }}>
                                <Logo />
                            </Box>
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                    }}
                >
                    {pages.map((page, index) => (
                        <CustomLink
                            key={page}
                            to={`/${page}`}
                            text={t(`pages.${page}`)}
                            sx={{ mr: `${index === pages.length - 1 ? '0' : '8px'}` }}
                        />
                    ))}
                </Box>
                {currentUser && <CreditorSelectorControl />}
                <Box>
                    <LocalizationSwitcher />
                </Box>
                <AccountInfo />
                {currentUser?.role === UserRoles.SettlAdmin ? (
                    <SystemMenu userRole={currentUser.role} />
                ) : null}
            </Toolbar>
        </AppBar>
    );
}

function CustomLink({ to, text, sx }: { to: string; text: string; sx?: SxProps }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { palette } = useTheme();

    const isActive = matchPath(
        {
            path: to,
            caseSensitive: false,
            end: false,
        },
        pathname,
    );

    const handleButtonClick = () => {
        navigate(to);
    };

    return (
        <Button
            onClick={handleButtonClick}
            sx={{
                px: '20px',
                '&:hover': { backgroundColor: palette.tableBorder },
                backgroundColor: isActive ? palette.primary.light : 'inherit',
                textTransform: 'none',
                ...sx,
            }}
        >
            <Box
                sx={{
                    textDecoration: 'none',
                    color: isActive ? palette.primary.dark : palette.secondary.contrastText,
                    fontSize: '16px',
                }}
            >
                {text}
            </Box>
        </Button>
    );
}
