import CircularProgress from '@mui/material/CircularProgress';

export const CircularLoader: React.FC = () => (
    <CircularProgress
        sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            transform: 'translate(-50%, -50%)',
            opacity: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        size={'6em'}
    />
);
