import { Box, FormControl, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAccessibleCreditorsService } from '../../components/hooks/useAccessibleCreditorsService';
import { Select } from '../../components/styled/Select';
import { CustomTooltip } from '../../components/styled/Tooltip';
import { ApplicationConstants } from '../../constants/ApplicationConstants';
import {
    accessibleCreditorsState,
    authenticatedUser,
    selectedCreditorState,
} from '../../store/recoilStore';
import { AccessibleCreditorDto } from '../../types/CreditorTypes';
import { CreditorUrlStateKeys } from './CreditorUrlStateKeys';

export function CreditorSelectorControl() {
    const [selectedCreditor, setSelectedCreditor] = useRecoilState(selectedCreditorState);
    const currentUser = useRecoilValue(authenticatedUser);
    const accessibleCreditors = useRecoilValue(accessibleCreditorsState);
    const [, setSearchParams] = useSearchParams();

    const { updateAccessibleCreditorsState } = useAccessibleCreditorsService();

    useEffect(() => {
        updateAccessibleCreditorsState();
    }, [currentUser]);

    return (
        <Box>
            {accessibleCreditors && (
                <FormControl fullWidth>
                    <Select
                        sx={{
                            height: '32px',
                            width: '268px',
                            '.MuiSelect-icon': {
                                fontSize: '18px',
                            },
                        }}
                        value={selectedCreditor?.Id}
                        onChange={(e) => {
                            const selectedCreditorId = e.target.value as number;
                            setSearchParams((currentParams) => {
                                currentParams.delete(CreditorUrlStateKeys.CreditorId);
                                return currentParams;
                            });
                            setSelectedCreditor(
                                accessibleCreditors?.find((x) => x.Id === selectedCreditorId) ??
                                    null,
                            );
                            localStorage.setItem(
                                ApplicationConstants.DcmCreditorId,
                                selectedCreditorId.toString(),
                            );
                        }}
                    >
                        {accessibleCreditors.map((creditor: AccessibleCreditorDto) => (
                            <MenuItem
                                sx={{
                                    width: '268px',
                                }}
                                key={creditor.Id}
                                value={creditor.Id}
                            >
                                <CustomTooltip
                                    title={creditor.Name.length > 27 ? creditor.Name : ''}
                                >
                                    <Typography overflow={'hidden'} textOverflow={'ellipsis'}>
                                        {creditor.Name}
                                    </Typography>
                                </CustomTooltip>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
}
